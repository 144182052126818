import { useRef, useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
  CardFooter,
} from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import { useForm } from 'react-hook-form'
import { sendData } from 'utli/apiService'
import useQuery from 'hooks/useQuery'
import { dataUrlToFile } from 'utli/fileConverter'
import CropperModal from 'components/cropperModal'
const EditUserAccount = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [cropImage, setCropImage] = useState(null)
  const inputFile = useRef(null)

  const [initState, setInitState] = useState({
    submitLoading: false,
    openCropper: false,
    image: null,
    croppedImage: null,
    imageError: false,
  })

  // const [initState, setInitState] = useState({
  //   submitLoading: false,
  // })

  const submitData = async (values) => {
   
    setInitState({ ...initState, submitLoading: true })

    const formData = new FormData()
   
    formData.append('name', values.name)
    formData.append('companyName', values.companyName)
    formData.append('phone', values.phone)

    if (cropImage) {
      const imageFile = await dataUrlToFile(cropImage)
      formData.append('image', imageFile)
    }
    formData.append('id', props.match.params.id)

    setInitState({ ...initState, submitLoading: true })
    const response = await sendData('user/update', formData)
    if (response) {
      NotificationManager.success('Saved')
     window.location='/user'
    }
    setInitState({ ...initState, submitLoading: false })
   
  }
  const {
    response: { userInfo },
    loading,
    error,
  } = useQuery(`user/detail/${props.match.params.id}`)
  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  console.log(userInfo.companyName);
  return (
    <>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <CardTitle className="d-flex justify-content-between">
                  <h2 className="mt-2">Edit User Account</h2>
                </CardTitle>
                <Row>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="userNameInput">User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="userNameInput"
                        {...register('name', {
                          required: 'Name is required',
                        })}
                        defaultValue={userInfo?.name}
                      ></input>
                    </FormGroup>
                    {errors.name && (
                      <span className="text-danger">
                        {errors?.name?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="comapnyNameInput">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyNameInput"
                        {...register('companyName', {
                          required: 'Company Name is required',
                        })}
                        defaultValue={userInfo?.companyName}
                      ></input>
                    </FormGroup>
                    {errors.companyName && (
                      <span className="text-danger">
                        {errors?.companyName?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="phoneInput">Phone</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phoneInput"
                        {...register('phone', {
                          required: 'Phone is required',
                        })}
                        defaultValue={userInfo?.phone}
                      ></input>
                      {errors.phone && (
                        <span className="text-danger">
                          {errors?.phone?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  {/* <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="passwordInput">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="passwordInput"
                        defaultValue={userInfo.password}
                        {...register('password', {
                          required: 'Password is required',
                        })}
                      ></input>
                      {errors.password && (
                        <span className="text-danger">
                          {errors?.password?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col> */}
                  <Col sm="12" className="pt-3">
                    <label>Image</label>
                    {cropImage ? (
                      <div>
                        <img
                          alt="placeholder containeer"
                          src={cropImage}
                          style={{
                            objectFit: 'cover',
                          }}
                          width="200"
                          height="200"
                          onClick={() => {
                            inputFile.current.click()
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          alt="category result"
                          src={userInfo.image}
                          style={{
                            objectFit: 'cover',
                          }}
                          width="200"
                          height="200"
                          onClick={() => {
                            inputFile.current.click()
                          }}
                        />
                      </div>
                    )}
                    {initState.imageError && (
                      <p className="text-danger">Image is required</p>
                    )}
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        if (event.target.files[0]) {
                          setInitState({
                            ...initState,
                            openCropper: true,
                            image: URL.createObjectURL(event.target.files[0]),
                          })
                        }
                      }}
                    />
                  </Col>
                </Row>

                {/* <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Edit User
                  </Button>
                </FormGroup> */}
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              type="submit"
              color="default"
              disabled={initState.submitLoading}
            >
              Edit User
            </Button>
          </CardFooter>
        </Card>
      </Form>
      {initState.openCropper && (
        <CropperModal
          isShow={initState.openCropper}
          image={initState.image}
          close={() => setInitState({ ...initState, openCropper: false })}
          setCroppedImage={async (image) => {
            setCropImage(image)
          }}
          x={2}
          y={2}
        />
      )}
    </>
  )
}
export default EditUserAccount

import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap'
import './setting.scss'
const Setting = () => {
  return (
    <>
      <Row>
        <Col md="2" sm="4" className="setting-menu">
          <Nav vertical>
            <NavItem className="bg-primary mb-2 p-2">
              <NavLink className="text-white" href="#">
                Payment Method
              </NavLink>
            </NavItem>
            <NavItem className="bg-primary mb-2 p-2">
              <NavLink className="text-white" href="#">
                Payment Method
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col md="10"></Col>
      </Row>
    </>
  )
}

export default Setting

import React, { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Table,
  CardFooter,
} from 'reactstrap'
import queryString from 'query-string'
import useQuery from 'hooks/useQuery'
import { paginationLimit } from 'variables/limits'
import CustomPagnation from 'components/pagination'
import { useHistory } from 'react-router'
import ReactExport from 'react-export-excel'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const Records = () => {
  const router = useHistory()
  const headers = [
    '#',
    'BL\\AWB No',
    'Type',
    'User',
    'UserType',
    'Current Status',
    // 'Current Remark',
    'Actions',
  ]

  const [exportedData, setExportedData] = useState([])
  const [exportedLoading, setExportedLoading] = useState(false)
  const query = queryString.parse(router.location.search)

  let url = 'orders/get?'
  let page = query.page || 1
  if (query.search) url += `number=${query.search}`
  if (query.page) url += `&page=${page}`

  const {
    response: { orderlist },
    loading,
    error,
  } = useQuery(url)

  // const { response: exportOrderList } = useQuery('orders/get')
  const importMap = {
    1: 'BL No. (or) AWB No.',
    2: 'ETA ( Estimate Time Arrival )',
    3: 'Delivery Order',
    4: 'Customs Declaration',
    5: 'Customs Classification and  Assess Duty',
    6: 'Port Clearance ',
    7: 'Empty Lift-Off at Depot / Truck Departure',
  }

  const exportMap = {
    1: 'Booking No (or) AWB No',
    2: 'ETD ( Estimate Time Arrival )',
    3: '102 and 103',
    4: 'Pick up Empty Container ',
    5: 'Customs Declaration ',
    6: 'Customs Classification and Assess Duty  ',
    7: 'Port Clearance Complete',
  }

  const { response } = useQuery('orders/getList')
  const getExportedData = async () => {
    setExportedLoading(true)
    setExportedData(
      response.orderlist.map((element) => {
        return element
      })
    )
    setExportedLoading(false)
  }

  // console.log(orderlist.data[1].user.name === undefined)

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Records</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                  onClick={getExportedData}
                >
                  <span className="btn-inner--text">
                    {exportedLoading ? 'Loading..' : 'Export Excel'}
                  </span>
                </Button>

                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                  href={`${router.location.pathname}`}
                >
                  <span className="btn-inner--text">View All</span>
                </Button>

                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  onClick={(e) => router.push('/records/create')}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">Add New</span>
                </Button>
              </Col>
            </Row>
          </CardTitle>
          <div className="d-flex justify-content-end">
            {exportedData.length > 0 && (
              <ExcelFile
                element={
                  <Button
                    style={{ marginLeft: 20, marginBottom: 10 }}
                    size="sm"
                  >
                    Download Excel({exportedData.length})
                  </Button>
                }
                filename={'recordList'}
              >
                <ExcelSheet data={exportedData} name="Data">
                  <ExcelColumn label="BL\AWB No" value="number" />
                  <ExcelColumn label="TYPE" value="type" />
                  <ExcelColumn
                    label="USER"
                    value={(col) =>
                      col.is_guest === '0' ? col.user?.name : col.guest_name
                    }
                  />
                  <ExcelColumn
                    label="USERTYPE"
                    value={(col) =>
                      col.is_guest === '0' ? 'Member User' : 'Guest User'
                    }
                  />
                  <ExcelColumn
                    label="STATUS"
                    value={(col) =>
                      col.type === 'import'
                        ? importMap[col.status]
                        : col.type === 'export'
                        ? exportMap[col.status]
                        : '-'
                    }
                  />
                </ExcelSheet>
              </ExcelFile>
            )}
            <span>Total {orderlist.total} records</span>
          </div>

          <Table responsive bordered hover className="text-center">
            <thead className="thead-light">
              <tr>
                {headers.map((element, index) => {
                  return (
                    <th
                      className=" sort"
                      data-sort={element}
                      scope="col"
                      key={`user-account-new-header-${index}`}
                    >
                      {element}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {orderlist.data.map((element, index) => (
                <tr key={`order-list-${index}`}>
                  <td>
                    {index + 1 + page * paginationLimit - paginationLimit}
                  </td>
                  <td>{element.number}</td>
                  <td>{element.type}</td>
                  <td>
                    {element.is_guest === '0'
                      ? element.user.name
                      : element.guest_name}
                  </td>
                  <td>
                    {element.is_guest === '0' ? 'Member User' : 'Guest User'}
                  </td>
                  <td>
                    {
                      // console.log(element.type==="import"?importMap[element.status]:element.type==="export"?exportMap[element.status]:'--')
                    }
                    {element.type === 'import'
                      ? importMap[element.status]
                      : element.type === 'export'
                      ? exportMap[element.status]
                      : '-'}
                  </td>
                  {/* <td>{}</td> */}
                  <td>
                    <Button
                      size="sm"
                      color="success"
                      onClick={() => {
                        router.push(`record/${element.id}`)
                      }}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
        {orderlist.total > paginationLimit && (
          <CardFooter className="d-flex justify-content-end">
            <CustomPagnation
              pageCount={orderlist.total / paginationLimit}
            ></CustomPagnation>
          </CardFooter>
        )}
      </Card>
    </>
  )
}
export default Records

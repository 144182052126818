import dayjs from 'dayjs'
export function convertToServerFormat(date) {
  var obj = new Date(date)
  return `${obj.getFullYear()}-${obj.getMonth() + 1}-${obj.getDate()}`
}

export function getTodayDate() {
  let obj = new Date()
  let date = obj.getDate()
  let month = obj.getMonth() + 1
  let year = obj.getFullYear()
  date = date < 10 ? `0${date}` : date
  month = month < 10 ? `0${month}` : month
  return `${year}-${month}-${date}`
}

export function getCurrentTime() {
  const currentDate = new Date()
  let currentHour =
    currentDate.getHours() > 12
      ? currentDate.getHours() - 12
      : currentDate.getHours()
  currentHour = currentHour < 10 ? `0${currentHour}` : currentHour

  let currentMin =
    currentDate.getMinutes() < 10
      ? `0${currentDate.getMinutes()}`
      : currentDate.getMinutes()
  let diff = currentDate.getHours() > 12 ? 'PM' : 'AM'

  return `${currentHour}:${currentMin}:${diff}`
}

export function isOverOneDay(created_at) {
  var createdDate = new Date(created_at)
  var today = new Date()
  var diff = (today.getTime() - createdDate.getTime()) / 1000
  diff /= 60 * 60
  diff = Math.abs(Math.round(diff))

  if (diff < 24) {
    return true
  } else {
    return false
  }
}

export function getReadableDateDisplay(time) {
  let timeObj = dayjs(time)
  let formattedDate = timeObj.format('YYYY/MM/DD')
  let sameDay = dayjs().isSame(formattedDate, 'day')
  if (sameDay) {
    let sameHour = dayjs().hour() === timeObj.hour()
    let minDiff = dayjs().minute() - timeObj.minute()
    let hourDiff = dayjs().hour() - timeObj.hour()
    let minText = minDiff > 1 ? 'minutes' : 'minute'
    let hourText = hourDiff > 1 ? 'hours' : 'hour'
    if (minDiff === 0) return ' Just Now'
    else if (sameHour) return `${minDiff} ${minText} ago`
    else return `${hourDiff} ${hourText} ago`
  }
  return formattedDate
}

import { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
} from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'
import useQuery from 'hooks/useQuery'

const CreateRecord = () => {
  const types = [
    {
      label: 'Export',
      value: 'export',
    },
    {
      label: 'Import',
      value: 'import',
    },
  ]

  const customerTypes = [
    {
      label: 'Member',
      value: '0',
    },
    {
      label: 'Guest',
      value: '1',
    },
  ]

  const {
    response: { userList },
  } = useQuery('users')
  // const filterCustomers = userList?.data.filter((element) => element.is_guest === "0");

  const customers =
    userList?.data === undefined
      ? []
      : userList?.data.map((element) => {
          return {
            value: element.id,
            label: `${element.name} (${element.companyName})`,
          }
        })

  const [customerType, setCustomerType] = useState(customerTypes)

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  const [initState, setInitState] = useState({
    submitLoading: false,
  })

  const submitData = async (values) => {
    setInitState({ ...initState, submitLoading: true })

    const response = await sendData('orders/store', values)
    if (response) {
      NotificationManager.success('Saved');
      window.location="/records";
    }
    setInitState({ ...initState, submitLoading: false })
    reset()
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Create Record</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <Row>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="tradeTypeInput">Type</label>
                      <Controller
                        control={control}
                        name="type"
                        className="tradeTypeInput"
                        defaultValue={types[0]?.value}
                        rules={{ required: true }}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            inputRef={ref}
                            classNamePrefix="addl-class"
                            options={types}
                            defaultValue={types[0]?.value}
                            value={types.find((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                          />
                        )}
                      />
                      {errors.type && (
                        <span className="text-danger">
                          {errors?.type?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="customerInput">Customer Type</label>
                      <Controller
                        control={control}
                        name="is_guest"
                        className="customerInput"
                        defaultValue={customerTypes[0]?.value}
                        rules={{ required: true }}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            inputRef={ref}
                            classNamePrefix="addl-class"
                            name="is_guest"
                            defaultValue={customerTypes[0]?.value}
                            options={customerTypes}
                            value={customerTypes.find((c) => c.value === value)}
                            onChange={(val) => {
                              onChange(val.value)
                              setCustomerType(val.value)
                            }}
                          />
                        )}
                      />
                      {errors.is_guest && (
                        <span className="text-danger">
                          {errors?.is_guest?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="billNoInput">BL / AWB No</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="billNoInput"
                        {...register('number', {
                          required: 'Bill Number is required',
                        })}
                      ></input>
                      {errors.number && (
                        <span className="text-danger">
                          {errors?.number?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  {customerType === '1' ? (
                    <Row>
                      <Col md={6}>
                        <FormGroup className="submit-form">
                          <label htmlFor="guestNameInput">
                            Guest User Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="guestName"
                            {...register('guest_name', {
                              required: 'Phone is required',
                            })}
                          ></input>
                          {errors.guest_name && (
                            <span className="text-danger">
                              {errors?.guest_name?.message}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="submit-form">
                          <label htmlFor="guestPhoneInput">Guest Phone</label>
                          <input
                            type="tel"
                            className="form-control"
                            id="billNo"
                            {...register('guest_phone', {
                              required: 'Phone is required',
                            })}
                          ></input>
                          {errors.guest_phone && (
                            <span className="text-danger">
                              {errors?.guest_phone?.message}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <Col md={4}>
                      <FormGroup className="submit-form">
                        <label htmlFor="emailInput">Customer</label>
                        <Controller
                          control={control}
                          name="user_id"
                          rules={{ required: true }}
                          defaultValue={customers[0]?.value}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              inputRef={ref}
                              classNamePrefix="addl-class"
                              options={customers}
                              // defaultValue={customers[0]?.value}
                              value={customers.find((c) => c.value === value)}
                              onChange={(val) => onChange(val.value)}
                            />
                          )}
                        />
                        {errors.user_id && (
                          <span className="text-danger">
                            {errors?.user_id?.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  )}
                </Row>

                <FormGroup className="submit-form__btn-section mt-5">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Add new record
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
export default CreateRecord

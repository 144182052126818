import React from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  CardFooter,
  Form,
} from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import draftToHtml from 'draftjs-to-html'
import useQuery from 'hooks/useQuery'
import {
  convertFromHTML,
  ContentState,
  EditorState,
  convertToRaw,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { sendData } from 'utli/apiService'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './index.scss'
function TermOfUse() {
  const [initState, setInitState] = React.useState({
    body: '',
  })

  const submitData = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('body', initState.body)
    const response = await sendData('tnc/store', formData)
    if (response) {
      NotificationManager.success('Save')
      setInitState({
        ...initState,
        submitLoading: false,
      })
      window.location.reload()
    }
  }

  const converttoHtml = (value) => {
    if (typeof value === 'object') {
      const rawContentState = convertToRaw(value.getCurrentContent())
      const markup = draftToHtml(rawContentState)
      return markup
    } else {
      return value
    }
  }

  const getDraft = (sampleMarkup) => {
    const blocksFromHTML = convertFromHTML(sampleMarkup ? sampleMarkup : '')
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    return EditorState.createWithContent(state)
  }

  React.useEffect(() => {
    if (data) {
      setInitState({
        ...initState,
        body: converttoHtml(data?.body),
      })
    }
  }, [data])

  const {
    response: { data },
    loading,
    error,
  } = useQuery('tnc')

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>

  return (
    <>
      <Card>
        <Form onSubmit={submitData}>
          <CardBody>
            <CardTitle className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Terms of Use</h3>
                </Col>
              </Row>
              <Row className="editor-main mt-3">
                <Col xs="6">
                  <Editor
                    toolbarClassName="editor-toolbar"
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor"
                    defaultEditorState={getDraft(data?.body)}
                    toolbar={{ options: ['list'] }}
                    name="coordination"
                    onEditorStateChange={(editorState) => {
                      const rawContentState = convertToRaw(
                        editorState.getCurrentContent()
                      )
                      const markup = draftToHtml(rawContentState)
                      setInitState({ ...initState, body: markup })
                    }}
                  />
                </Col>
              </Row>
            </CardTitle>
            <CardFooter className="d-flex justify-content-start">
              <Button
                color="default"
                type="submit"
                disabled={initState.submitLoading}
              >
                Save
              </Button>
            </CardFooter>
          </CardBody>
        </Form>
      </Card>
    </>
  )
}

export default TermOfUse

import ReactExport from 'react-export-excel'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ExcelExport = ({ customButton, data, columns, fileName }) => {
  return (
    <ExcelFile element={customButton} filename={fileName}>
      <ExcelSheet data={data} name="Data">
        {columns.map((element, index) => (
          <ExcelColumn
            key={`excel-export-${index}`}
            label={element.title}
            value={element.value}
          />
        ))}
      </ExcelSheet>
    </ExcelFile>
  )
}
export default ExcelExport

import React, { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Table,
  CardFooter,
} from 'reactstrap'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import CustomPagnation from 'components/pagination'
import useQuery from 'hooks/useQuery'
import { sendData } from 'utli/apiService'
import { paginationLimit } from 'variables/limits'
import ExcelExport from 'components/excelExport'

const User = () => {
  const router = useHistory();
  const headers = [
    '#',
    'User Name',
    'Phone',
    'Company Name',
    'Image',
    'Actions',
  ];
  const [exportedData, setExportedData] = useState([]);
  const [exportedLoading, setExportedLoading] = useState(false);
  const query = queryString.parse(router.location.search);

  let url = 'users?'
  let page = query.page || 1
  if (query.search) url += `keyword=${query.search}`
  if (query.page) url += `&page=${page}`

  const {
    response: { userList },
    loading,
    error,
  } = useQuery(url);
  // console.log(userList)

  const deleteUser = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete.Data cannot be recovered',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const response = await sendData(`user/delete/${id}`)
            if (response) {
              window.location.reload()
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  const getExportedData = async () => {
    setExportedLoading(true)
    setExportedData(
      userList.data.map((element) => {
        // console.log(element)
        return element
      })
    )
    setExportedLoading(false)
  }

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Users</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                  onClick={getExportedData}
                >
                  <span className="btn-inner--text">
                    {exportedLoading ? 'Loading..' : 'Export Excel'}
                  </span>
                </Button>

                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                  href={`${router.location.pathname}`}
                >
                  <span className="btn-inner--text">View All</span>
                </Button>

                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  onClick={(e) => router.push('/user/create')}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">Add New</span>
                </Button>
              </Col>
            </Row>
          </CardTitle>
          <div className="d-flex justify-content-end">
            {exportedData.length > 0 && (
              <ExcelExport
                data={exportedData}
                fileName={'userList'}
                columns={[
                  { title: 'User Name', value: 'name' },
                  { title: 'Phone', value: 'phone' },
                  { title: 'Company Name', value: 'companyName' },
                ]}
                customButton={
                  <Button
                    style={{ marginLeft: 20, marginBottom: 10 }}
                    size="sm"
                  >
                    Download Excel({exportedData.length})
                  </Button>
                }
              />
            )}
            <span>Total {userList.total} users</span>
          </div>

          <Table responsive bordered hover className="text-center">
            <thead className='thead-light'>
              <tr>
                {headers.map((element, index) => {
                  return (
                    <th
                      className=" sort"
                      data-sort={element}
                      scope="col"
                      key={`user-account-new-header-${index}`}
                    >
                      {element}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {userList.data.map((element, index) => {
                return (
                  <tr key={`user-account-list-${element.id}`}>
                    <td>
                      {index + 1 + page * paginationLimit - paginationLimit}
                    </td>
                    <td>{element.name}</td>

                    <td>{element.phone}</td>

                    <td>{element.companyName}</td>

                    <td>
                      <img
                        src={element.image}
                        alt="profile"
                        width="80"
                        height="80"
                        style={{
                          objectFit:"cover"
                        }}
                      />
                    </td>
                    <td>
                      <div>
                        <Button
                          size="sm"
                          className="btn-icon-only"
                          color="success"
                          onClick={() => {
                            router.push(`/user/edit/${element.id}`)
                          }}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-pen" />
                          </span>
                        </Button>
                        <Button
                          size="sm"
                          className="btn-icon-only"
                          color="danger"
                          onClick={() => deleteUser(element.id)}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-trash" />
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </CardBody>
        {userList.total > paginationLimit && (
          <CardFooter className="d-flex justify-content-end">
            <CustomPagnation
              pageCount={userList.total / paginationLimit}
            ></CustomPagnation>
          </CardFooter>
        )}
      </Card>
    </>
  )
}
export default User

import React from 'react'
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap'
import useQuery from 'hooks/useQuery'
const Home = () => {
  let url = 'dashboard?'
  const {
    response:{info},
    loading,
    error,
  } = useQuery(url)

  console.log(info);

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>

  return (
    <Row>
      <Col md="4" sm="6">
        <Card className="card-stats mb-4 pb-3 pt-3 mb-3">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle className="text-uppercase text-muted mb-0">
                  Today Records
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {info?.TotalOrders}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                  <i className="fas fa-chart-bar" />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col md="4" sm="6">
        <Card className="card-stats mb-4 pb-3 pt-3 mb-3">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle className="text-uppercase text-muted mb-0">
                  This Month Records
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {info?.TotalOrdersThisMonth}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                  <i className="fas fa-chart-bar" />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col md="4" sm="6">
        <Card className="card-stats mb-4 pb-3 pt-3 mb-3">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle className="text-uppercase text-muted mb-0">
                  This Year Records
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {info?.TotalOrdersThisYear}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                  <i className="fas fa-chart-bar" />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col md="4" sm="6">
        <Card className="card-stats mb-4 pb-3 pt-3 mb-3">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle className="text-uppercase text-muted mb-0">
                  New Users
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {info?.TotalNewUsersThisYear}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                  <i className="fas fa-chart-bar" />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col md="4" sm="6">
        <Card className="card-stats mb-4 pb-3 pt-3 mb-3">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle className="text-uppercase text-muted mb-0">
                  Total Users
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {info?.TotalUsersThisYear}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                  <i className="fas fa-chart-bar" />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Home

import useQuery from 'hooks/useQuery'
import { NotificationManager } from 'react-notifications'
import { sendData } from 'utli/apiService'
import { getReadableDateDisplay } from 'lib/dateFormat'
import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardTitle, Table, Button, Row, Col } from 'reactstrap'
import '../../lib/getCurrentUser'
import { getCurrentUser } from '../../lib/getCurrentUser'
import styles from './detail.module.css'
const Records = (props) => {
  const router = useHistory()
  const {
    response: { orderInfo },
    loading: orderLoading,
    error: orderLoadingErr,
  } = useQuery(`orders/detail/${props.match.params.id}`)

  if (orderLoading) return <h3>Loading...</h3>
  if (orderLoadingErr) return <h3>{orderLoadingErr.response.message}</h3>

  const importMap = [
    {
      title1: 'BL No. (or) AWB No.',
      desc1:
        'An airway bill or AWB is a document that accompanies goods shipped by an international courier, which allow for tracking.',
    },
    {
      title2: 'ETA ( Estimate Time Arrival )',
      desc2:
        'In the logistics industry, ETA (estimated time of arrival) indicates when a vehicle, cargo ship, or other modes of transportation will arrive at its final destination.',
    },
    {
      title3: 'Delivery Order',
      desc3:
        'Delivery order is the order given by carrier to the party to take delivery of goods. Once cargo arrived at port of destination, the goods are moved to customs ...',
    },
    {
      title4: 'Customs Declaration',
      desc4:
        'A customs declaration is an official document that lists and gives details of goods that are being imported or exported. In legal terms, a customs declaration ...',
    },
    {
      title5: 'Customs Classification and  Assess Duty',
      desc5:
        'Classification is not just used to determine the customs duty rate for a specific subheading. It is also used to apply non-tariff measures.',
    },
    {
      title6: 'Port Clearance ',
      desc6:
        'Port papers clearance software, Navigator Port helps plan and prepare port papers for port clearance,sailing through controlled waters, eNOAD support.',
    },
    {
      title7: 'Empty Lift-Off at Depot / Truck Departure',
      desc7:
        'Moreover, the lift off in the interphase between truck and depot can in addition to ... empty container must exit the truck and register the loaded empty ...',
    },
  ]

  const exportMap = [
    {
      title1: 'Booking No (or) AWB No',
      desc1:
        'An airway bill or AWB is a document that accompanies goods shipped by an international courier, which allow for tracking.',
    },
    {
      title2: 'ETD ( Estimate Time Arrival )',
      desc2:
        'In the logistics industry, ETA (estimated time of arrival) indicates when a vehicle, cargo ship, or other modes of transportation will arrive at its final destination.',
    },
    {
      title3: '102 and 103',
      desc3:
        'Delivery order is the order given by carrier to the party to take delivery of goods. Once cargo arrived at port of destination, the goods are moved to customs ...',
    },
    {
      title4: 'Pick up Empty Container',
      desc4:
        'A customs declaration is an official document that lists and gives details of goods that are being imported or exported. In legal terms, a customs declaration ...',
    },
    {
      title5: 'Customs Declaration',
      desc5:
        'Classification is not just used to determine the customs duty rate for a specific subheading. It is also used to apply non-tariff measures.',
    },
    {
      title6: 'Customs Classification and Assess Duty',
      desc6:
        'The action of examining beforehand; an instance of this, a previous or prior examination. Origin. Mid 17th century; earliest use found in William ...',
    },
    {
      title7: 'Port Clearance Complete',
      desc7:
        'Port papers clearance software, Navigator Port helps plan and prepare port papers for port clearance, sailing through controlled waters, eNOAD support.',
    },
  ]
  let status = ''
  if (orderInfo.type === 'import' && orderInfo.order_logs.length !== 0) {
    if (orderInfo.order_logs.length <= 7)
      status =
        importMap[orderInfo.order_logs.length - 1][
          `title${orderInfo.order_logs.length}`
        ]
  }
  if (orderInfo.type === 'export' && orderInfo.order_logs.length !== 0) {
    if (orderInfo.order_logs.length <= 7)
      status =
        exportMap[orderInfo.order_logs.length - 1][
          `title${orderInfo.order_logs.length}`
        ]
  }

  const deleteData = async () => {
    await sendData('orders/delete', { id: orderInfo.id })
    NotificationManager.success('Deleted')
    router.push('/records')
  }

  return (
    <>
      <Row>
        <Col lg={12} md={12} className="mx-auto my-auto">
          <Card>
            <CardTitle className="pt-2 pl-5">
              <h3>No: {orderInfo.number}</h3>
              <h3>Current Status : {status} </h3>
              <h3>
                {orderInfo.is_guest === '0'
                  ? `Member : ${orderInfo?.user?.name} (${orderInfo?.user?.companyName})`
                  : `Guest : ${orderInfo?.guest_name}`}
              </h3>
              {getCurrentUser()?.role === 'admin' ? (
                <h3>
                  Income : {orderInfo.income} ---- Expense : {orderInfo.expense}
                </h3>
              ) : (
                <div></div>
              )}
              {getCurrentUser()?.role === 'admin' ? (
                <Button
                  size="sm"
                  color="default"
                  onClick={() => {
                    router.push(`edit/${orderInfo.id}`)
                  }}
                >
                  Edit
                </Button>
              ) : (
                <div></div>
              )}
              <Button size="sm" color="default" onClick={deleteData}>
                Delete
              </Button>
            </CardTitle>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th style={{ width: '100px' }} className={styles.table}>
                      No
                    </th>
                    <th style={{ width: '100px' }} className={styles.table}>
                      Step
                    </th>
                    <th style={{ width: '100px' }} className={styles.table}>
                      Status
                    </th>
                    <th style={{ width: '100px' }} className={styles.table}>
                      Last Update
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderInfo.type === 'import'
                    ? importMap.map((element, index) => {
                        return (
                          <tr key={`import-map-${index}`}>
                            <td>{index + 1}</td>
                            <td>
                              <h5>{importMap[index][`title${index + 1}`]}</h5>
                              <p>
                                {importMap[index][`desc${index + 1}`].substring(
                                  0,
                                  99
                                ) + '...'}
                              </p>
                            </td>
                            <td>
                              {orderInfo.order_logs.length > index &&
                              orderInfo.order_logs.find(
                                (e) =>
                                  parseInt(e.status) === parseInt(index + 1)
                              ) ? (
                                <span className="text-success">Complete</span>
                              ) : orderInfo.order_logs.find(
                                  (e) => parseInt(e.status) === parseInt(index)
                                ) ||
                                (parseInt(orderInfo.status) === 0 &&
                                  index === 0) ? (
                                <Link
                                  to={`update/${orderInfo.id}/${(
                                    index + 1
                                  ).toString()}`}
                                >
                                  {' '}
                                  <Button
                                    size="sm"
                                    color="default"
                                    onClick={() => {
                                      // router.push({
                                      //   pathname: `update/${orderInfo.id}/${(index+1).toString()}`,
                                      // });
                                    }}
                                  >
                                    Update
                                  </Button>
                                </Link>
                              ) : (
                                <div></div>
                              )}
                            </td>
                            <td>
                              {orderInfo.order_logs.length > index &&
                                getReadableDateDisplay(
                                  orderInfo.order_logs[index].created_at
                                )}
                            </td>
                          </tr>
                        )
                      })
                    : exportMap.map((element, index) => {
                        // console.log(element)
                        return (
                          <tr key={`export-map-${index}`}>
                            <td>{index + 1}</td>
                            <td>
                              <h5>{exportMap[index][`title${index + 1}`]}</h5>
                              <p>
                                {exportMap[index][`desc${index + 1}`].substring(
                                  0,
                                  99
                                ) + '...'}
                              </p>
                            </td>
                            <td>
                              {orderInfo.order_logs.length > index &&
                              orderInfo.order_logs.find(
                                (e) =>
                                  parseInt(e.status) === parseInt(index + 1)
                              ) ? (
                                <span className="text-success">Complete</span>
                              ) : orderInfo.order_logs.find(
                                  (e) => parseInt(e.status) === parseInt(index)
                                ) ||
                                (parseInt(orderInfo.status) === 0 &&
                                  index === 0) ? (
                                <Button
                                  size="sm"
                                  color="default"
                                  onClick={() => {
                                    router.push({
                                      pathname: `update/${orderInfo.id}/${
                                        index + 1
                                      }`,
                                      state: { status: (index + 1).toString() },
                                    })
                                  }}
                                >
                                  Update
                                </Button>
                              ) : (
                                <div></div>
                              )}
                            </td>
                            <td>
                              {orderInfo.order_logs.length > index &&
                                getReadableDateDisplay(
                                  orderInfo.order_logs[index].created_at
                                )}
                            </td>
                          </tr>
                        )
                      })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default Records

import React, { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Table,
} from 'reactstrap'
import queryString from 'query-string'
import Select from 'react-select'
import { Link, useHistory } from 'react-router-dom'
// import { confirmAlert } from 'react-confirm-alert'
import useQuery from 'hooks/useQuery'
// import { sendData } from 'utli/apiService'
import { paginationLimit } from 'variables/limits'
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile'
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet'
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn'
import ReactDatetime from 'react-datetime'
import { element } from 'prop-types'

const Finance = () => {
  const router = useHistory()
  const headers = [
    '#',
    'Company Name',
    'User Name',
    'Current Status',
    'Income',
    'Expense',
    'Profit',
    // 'Current Remark',
    'Actions',
  ]
  const [exportedData, setExportedData] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [selectedMember, setSelectedMember] = useState(null)
  const [exportedLoading, setExportedLoading] = useState(false)
  const query = queryString.parse(router.location.search)

  let url = 'orders/get/finance?'

  let page = query.page || 1
  if (query.start_date && query.end_date)
    url += `&start_date=${query.start_date}&end_date=${query.end_date}`
  if (query.user_id) url += `&user_id=${query.user_id}`
  if (query.page) url += `&page=${page}`
  //if (filterDate) url += `start_date=${startDate}&end_date=${endDate}`

  const {
    response: { records },
    loading,
    error,
  } = useQuery(url)

  const getIncomeSubTotal = () => {
    let incomeSubTotal = 0;

    records.map((element) => {
      incomeSubTotal += parseInt(element.income);
    })
    return incomeSubTotal;
  }

  const getExpenseSubTotal = () => {
    let expensesubTotal = 0;

    records.map((element) => {
      expensesubTotal += parseInt(element.expense);
    })
    return expensesubTotal;
  }

  const getProfitSubTotal = () => {
    let profitSubTotal = 0;
    records.map((element) => {
      profitSubTotal += parseInt(element.income) - parseInt(element.expense);
    });
    return profitSubTotal;
  }

  const {
    response: { userList },
  } = useQuery('users')
  // const filterCustomers = userList?.data.filter((element) => element.is_guest === "0");

  const customers =
    userList?.data === undefined
      ? []
      : userList?.data.map((element) => {
        return {
          value: element.id,
          label: `${element.name} (${element.companyName})`,
        }
      })

  const importMap = {
    1: 'BL No. (or) AWB No.',
    2: 'ETA ( Estimate Time Arrival )',
    3: 'Delivery Order',
    4: 'Customs Declaration',
    5: 'Customs Classification and  Assess Duty',
    6: 'Port Clearance ',
    7: 'Empty Lift-Off at Depot / Truck Departure',
  }

  const exportMap = {
    1: 'Booking No (or) AWB No',
    2: 'ETD ( Estimate Time Arrival )',
    3: '102 and 103',
    4: 'Pick up Empty Container ',
    5: 'Customs Declaration ',
    6: 'Customs Classification and Assess Duty  ',
    7: 'Port Clearance Complete',
  }

  const getExportedData = async () => {
    setExportedLoading(true)
    setExportedData(
      records.map((element) => {
        return element
      })
    )
    setExportedLoading(false)
  }
  // records !==undefined && console.log(records[0].current_status.description)
  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Finance</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                  onClick={getExportedData}
                >
                  <span className="btn-inner--text">
                    {exportedLoading ? 'Loading..' : 'Export Excel'}
                  </span>
                </Button>

                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                  href={`${router.location.pathname}`}
                >
                  <span className="btn-inner--text">View All</span>
                </Button>
              </Col>
            </Row>
          </CardTitle>
          <Row>
            <Col md="3">
              <label>Customer / Company</label>

              <Select
                classNamePrefix="addl-class"
                options={customers}
                value={customers.find((c) => c.value === selectedMember)}
                onChange={(val) => setSelectedMember(val.value)}
              />
            </Col>
            <Col md="3">
              <label>Start Date</label>
              <ReactDatetime
                inputProps={{
                  placeholder: 'Start Date',
                }}
                onChange={(event) => {
                  setStartDate(event.format('YYYY-MM-DD'))
                }}
                timeFormat={false}
              />
            </Col>
            <Col md="3">
              <label>End Date</label>
              <ReactDatetime
                inputProps={{
                  placeholder: 'End Date',
                }}
                onChange={(event) => {
                  setEndDate(event.format('YYYY-MM-DD'))
                }}
                timeFormat={false}
              />
            </Col>
            <Col md="3" className='mt-4' >

              <Button
                color="success"
                type="button"
                size='md'
                onClick={() => {
                  let query = '?'
                  if (startDate && endDate) {
                    query += `&start_date=${startDate}&end_date=${endDate}`
                  }
                  if (selectedMember) {
                    query += `&user_id=${selectedMember}`
                  }
                  router.push(`/finance${query}`)
                }}
              >
                Check
              </Button>
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-3">
            {exportedData.length > 0 && (
              <ExcelFile
                element={
                  <Button
                    style={{ marginLeft: 20, marginBottom: 10 }}
                    size="sm"
                  >
                    Download Excel({exportedData.length})
                  </Button>
                }
                filename={'financeList'}
              >
                <ExcelSheet data={exportedData} name="Data">
                  <ExcelColumn
                    label="COMPANY NAME"
                    value={(col) => col.user.companyName}
                  />
                  <ExcelColumn
                    label="USER NAME"
                    value={(col) => col.user.name}
                  />
                  <ExcelColumn
                    label="CURRENT STATUS"
                    value={(col) =>
                      col.type === 'import'
                        ? importMap[col.current_status?.status]
                        : col.type === 'export'
                          ? exportMap[col.current_status?.status]
                          : '-'
                    }
                  />
                  <ExcelColumn label="INCOME" value="income" />
                  <ExcelColumn label="EXPENSE" value="expense" />
                  <ExcelColumn label="PROFIT" value ={(col)=>
                    parseInt(col.income) - parseInt(col.expense)
                }/>
                  {/* <ExcelColumn
                    label="CURRENT REMARK"
                    value={(col) => col.current_status?.description}
                  /> */}
                  {/* <ExcelColumn
                    label="TOTAL INCOME"
                    value={getIncomeSubTotal()}
                  />
                  <ExcelColumn
                    label="TOTAL EXPENSE"
                    value={getExpenseSubTotal()}
                  /> */}
                </ExcelSheet>
              </ExcelFile>
            )}
            {/* <span>Total {records.total} users</span> */}
          </div>

          <Table responsive bordered hover className="text-center">
            <thead className='thead-light'>
              <tr>
                {headers.map((element, index) => {
                  return (
                    <th
                      className=" sort"
                      data-sort={element}
                      scope="col"
                      key={`user-account-new-header-${index}`}
                    >
                      {element}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {records.map((element, index) => (
                <tr key={`order-list-${index}`}>
                  <td>
                    {index + 1 + page * paginationLimit - paginationLimit}
                  </td>
                  <td>{element.user.companyName}</td>
                  <td>{element.user.name}</td>
                  <td>
                  {element.type === 'import'
                      ? importMap[element.status]
                      : element.type === 'export'
                      ? exportMap[element.status]
                      : '-'}
                  </td>
                  <td>{element.income}</td>
                  <td>{element.expense}</td>
                  <td>{element.income - element.expense}</td>
                  {/* {element.current_status !== null ? (
                    <td>{element.current_status.description}</td>
                  ) : (
                    <td>-</td>
                  )} */}
                  <td>
                    <Link to={`/record/${element.id}`}>
                      <Button size="sm" color="success">
                        View
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4} style={{ fontSize: "16px", fontWeight: "bold" }}>Sub Total :</td>
                <td >{getIncomeSubTotal()}</td>
                <td>{getExpenseSubTotal()}</td>
                <td>{getProfitSubTotal()}</td>
                <td colSpan={2}></td>
              </tr>
            </tfoot>
          </Table>
        </CardBody>
        {/* {records.total > paginationLimit && (
          <CardFooter className="d-flex justify-content-end">
            <CustomPagnation
              pageCount={records.total / paginationLimit}
            ></CustomPagnation>
          </CardFooter>
        )} */}
      </Card>
    </>
  )
}
export default Finance

import { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
} from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import { Controller, useForm } from 'react-hook-form'
import { sendData } from 'utli/apiService'
import useQuery from 'hooks/useQuery'
import Select from 'react-select'
const EditAdminAccount = (props) => {
  const roles = [
    {
      label: 'Admin',
      value: 'admin',
    },
    {
      label: 'Manager',
      value: 'manager',
    },
    {
      label: 'Staff',
      value: 'staff',
    },
  ];
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const [initState, setInitState] = useState({
    submitLoading: false,
  })

  const submitData = async (values) => {
    values.id = Number(props.match.params.id);
    setInitState({ ...initState, submitLoading: true })
    const response = await sendData('admin/update', values)
    if (response) {
      NotificationManager.success('Saved')
      window.location ='/admin'
    }
    setInitState({ ...initState, submitLoading: false })
    
  }
  const {
    response: { adminInfo },
    loading,
    error,
  } = useQuery(`admin/get/${props.match.params.id}`)
  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col md="4">
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Edit Admin Account</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <FormGroup className="submit-form">
                  <label htmlFor="userNameInput">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="userNameInput"
                    {...register('name', {
                      required: 'Name is required',
                    })}
                    defaultValue={adminInfo.username}
                  ></input>
                  {errors.username && (
                    <span className="text-danger">
                      {errors?.username?.message}
                    </span>
                  )}
                </FormGroup>
                <FormGroup>
                  <label htmlFor="roleInput">Role</label>
                  <Controller
                    control={control}
                    name="role"
                    className="roleInput"
                    defaultValue={adminInfo?.role}
                    rules={{ required: true }}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={roles}
                        defaultValue={adminInfo?.role}
                        value={roles.find((c) => c.value === value)}
                        onChange={(val) => 
                          // console.log(val)
                          onChange(val.value)
                        }
                      />
                    )}/>
                </FormGroup>
                {/* <FormGroup className="submit-form">
                  <label htmlFor="phoneInput">Role</label>
                  <input
                    type="text"
                    className="form-control"
                    id="roleInput"
                    {...register('role', {
                      required: 'Role is required',
                    })}
                    defaultValue={adminInfo.role}
                  ></input>
                  {errors.role && (
                    <span className="text-danger">
                      {errors?.role?.message}
                    </span>
                  )}
                </FormGroup> */}

                <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Edit User
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
export default EditAdminAccount

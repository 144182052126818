import { useRef, useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'
import { dataUrlToFile } from 'utli/fileConverter'
import CropperModal from 'components/cropperModal'

const CreateUserAccount = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [cropImage, setCropImage] = useState(null)
  const inputFile = useRef(null)

  const [initState, setInitState] = useState({
    submitLoading: false,
    openCropper: false,
    image: null,
    croppedImage: null,
    imageError: false,
  })

  const submitData = async (values) => {
    // console.log(values)
    setInitState({ ...initState, submitLoading: true })

    const formData = new FormData()

    formData.append('name', values.name)
    formData.append('companyName', values.companyName)
    formData.append('phone', values.phone)
    formData.append('password', values.password)

    if (cropImage) {
      console.log(cropImage)
      const imageFile = await dataUrlToFile(cropImage)
      formData.append('image', imageFile)
    }

    const response = await sendData('user/io-register', formData)
    if (response) {
      NotificationManager.success('Saved')
      window.location='/user'
      setInitState({ ...initState, croppedImage: null, submitLoading: false })
      reset()
      setCropImage(null)
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Create User Account</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <Row>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="userNameInput">User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="userNameInput"
                        {...register('name', {
                          required: 'Name is required',
                        })}
                      ></input>
                    </FormGroup>
                    {errors.name && (
                      <span className="text-danger">
                        {errors?.name?.message}
                      </span>
                    )}
                  </Col>

                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="companyNameInput">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyNameInput"
                        {...register('companyName', {
                          required: 'Company Name is required',
                        })}
                      ></input>
                    </FormGroup>
                    {errors.companyName && (
                      <span className="text-danger">
                        {errors?.companyName?.message}
                      </span>
                    )}
                  </Col>

                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="phoneInput">Phone</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phoneInput"
                        {...register('phone', {
                          required: 'Phone is required',
                        })}
                      ></input>
                      {errors.phone && (
                        <span className="text-danger">
                          {errors?.phone?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="passwordInput">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="passwordInput"
                        {...register('password', {
                          required: 'Password is required',
                        })}
                      ></input>
                      {errors.password && (
                        <span className="text-danger">
                          {errors?.password?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <label>Image</label>
                  {cropImage ? (
                    <div>
                      <img
                        alt="croped result"
                        src={cropImage}
                        width="400"
                        height="400"
                        onClick={() => {
                          inputFile.current.click()
                        }}
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        inputFile.current.click()
                      }}
                      style={{
                        width: 400,
                        height: 400,
                        background: 'grey',
                        cursor: 'pointer',
                      }}
                    ></div>
                  )}
                  {initState.imageError && (
                    <p className="text-danger">Image is required</p>
                  )}
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        setInitState({
                          ...initState,
                          openCropper: true,
                          image: URL.createObjectURL(event.target.files[0]),
                        })
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Add new user
                  </Button>
                </FormGroup>
              </Form>
              {initState.openCropper && (
                <CropperModal
                  isShow={initState.openCropper}
                  image={initState.image}
                  close={() =>
                    setInitState({ ...initState, openCropper: false })
                  }
                  setCroppedImage={async (image) => {
                    setCropImage(image)
                  }}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
export default CreateUserAccount

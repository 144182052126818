import { useState, useRef } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
} from 'reactstrap'

import { useForm } from 'react-hook-form'
import CropperModal from 'components/cropperModal'
import { dataUrlToFile } from 'utli/fileConverter'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'
import useQuery from 'hooks/useQuery'
const EditCategory = (props) => {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm()
  const [initState, setInitState] = useState({
    openIconCropper: false,
    openCropper: false,
    icon: null,
    image: null,
    croppedIcon: null,
    croppedImage: null,
    iconError: false,
    imageError: false,
  })

  const [cropIcon, setCropIcon] = useState(null)
  const [cropImage, setCropImage] = useState(null)
  const inputIconFile = useRef(null)
  const inputFile = useRef(null)

  const submitData = async (values) => {
    // if (!cropIcon) {
    //   setInitState({ ...initState, iconError: true })
    // } else if (!cropImage) {
    //   setInitState({ ...initState, imageError: true })
    // }
    // else{
    const formData = new FormData()
    formData.append('name', values.name)
    formData.append('description', values.description)
    if (cropIcon) {
      const iconFile = await dataUrlToFile(cropIcon)
      formData.append('logo', iconFile)
    }

    if (cropImage) {
      const imageFile = await dataUrlToFile(cropImage)
      formData.append('image', imageFile)
    }

    formData.append('id', props.match.params.id)
    // if (cropImage) {
    //   const imageFile = await dataUrlToFile(cropImage)
    //   formData.append('image', imageFile)
    // }
    const response = await sendData('services/update', formData)
    if (response) {
      NotificationManager.success('Save')
      window.location ='/companyService';
      setInitState({ ...initState, croppedImage: null })
      setCropImage(null)
    }
    // }
  }

  const {
    response: { serviceinfo },
    loading,
    error,
  } = useQuery(`services/detail/${props.match.params.id}`)

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <Container>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card>
          <CardTitle className="pt-2 pl-4 bg-dark">
            <h3 className="text-white">Edit Service</h3>
          </CardTitle>
          <CardBody>
            <Row>
              <Col md="6" sm="12">
                <label>Name</label>
                <input
                  className="form-control"
                  {...register('name', {
                    required: 'Name is required',
                  })}
                  defaultValue={serviceinfo.name}
                />
                {errors.name && (
                  <span className="text-danger">{errors?.name?.message}</span>
                )}
              </Col>
              <Col sm="12" className="mt-2">
                <label>Description</label>
                <textarea
                  className="form-control"
                  defaultValue={serviceinfo.description}
                  {...register('description', {
                    required: 'Description is required',
                  })}
                />
                {errors.description && (
                  <span className="text-danger">
                    {errors?.description?.message}
                  </span>
                )}
              </Col>
              <Col sm="12" className="pt-3">
                <label>Logo</label>
                {cropIcon ? (
                  <div>
                    <img
                      alt="placeholder containeer"
                      src={cropIcon}
                      style={{
                        objectFit: 'cover',
                      }}
                      width="200"
                      height="200"
                      onClick={() => {
                        inputIconFile.current.click()
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      alt="category result"
                      src={serviceinfo.logo}
                      style={{
                        objectFit: 'cover',
                      }}
                      width="200"
                      height="200"
                      onClick={() => {
                        inputIconFile.current.click()
                      }}
                    />
                  </div>
                )}
                {initState.iconError && (
                  <p className="text-danger">Logo is required</p>
                )}
                <input
                  type="file"
                  id="file"
                  ref={inputIconFile}
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    if (event.target.files[0]) {
                      setInitState({
                        ...initState,
                        openIconCropper: true,
                        icon: URL.createObjectURL(event.target.files[0]),
                      })
                    }
                  }}
                />
              </Col>
              <Col sm="12" className="pt-3">
                <label>Image</label>
                {cropImage ? (
                  <div>
                    <img
                      alt="placeholder containeer"
                      src={cropImage}
                      style={{
                        objectFit: 'cover',
                      }}
                      width="400"
                      height="400"
                      onClick={() => {
                        inputFile.current.click()
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      alt="category result"
                      src={serviceinfo.image}
                      style={{
                        objectFit: 'cover',
                      }}
                      width="400"
                      height="400"
                      onClick={() => {
                        inputFile.current.click()
                      }}
                    />
                  </div>
                )}
                {initState.imageError && (
                  <p className="text-danger">Image is required</p>
                )}
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    if (event.target.files[0]) {
                      setInitState({
                        ...initState,
                        openCropper: true,
                        image: URL.createObjectURL(event.target.files[0]),
                      })
                    }
                  }}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="d-flex justify-content-center">
            <Button color="default" type="submit">
              Save
            </Button>
          </CardFooter>
        </Card>
      </Form>
      {initState.openIconCropper && (
        <CropperModal
          isShow={initState.openIconCropper}
          image={initState.icon}
          close={() => setInitState({ ...initState, openIconCropper: false })}
          setCroppedImage={async (icon) => {
            setCropIcon(icon)
          }}
          x={2}
          y={2}
        />
      )}

      {initState.openCropper && (
        <CropperModal
          isShow={initState.openCropper}
          image={initState.image}
          close={() => setInitState({ ...initState, openCropper: false })}
          setCroppedImage={async (image) => {
            setCropImage(image)
          }}
        />
      )}
    </Container>
  )
}
export default EditCategory

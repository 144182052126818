import { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
  CardFooter,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'
import { useParams } from 'react-router'

const UpdatOrderRecord = (props) => {
  const params = useParams()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [initState, setInitState] = useState({
    submitLoading: false,
    openCropper: false,
    image: null,
    croppedImage: null,
    imageError: false,
  })

  const submitData = async (values) => {
    // console.log(values);
    values.order_id = props.match.params.id
    setInitState({ ...initState, submitLoading: true })

    const response = await sendData('orderlog/store', values)
    if (response) {
      NotificationManager.success('Saved')
      window.location = `/record/${props.match.params.id}`
      setInitState({ ...initState, croppedImage: null, submitLoading: false })
      reset()
    }
  }

  return (
    <>
      {' '}
      <Form onSubmit={handleSubmit(submitData)}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <CardTitle className="d-flex justify-content-between">
                  <h2 className="mt-2">Update Record</h2>
                </CardTitle>
                <Col md={4}>
                  <FormGroup className="submit-form">
                    <label htmlFor="descInput">Status</label>
                    <input
                      type="text"
                      className="form-control"
                      id="descInput"
                      readOnly
                      defaultValue={params.status}
                      {...register('status', {
                        required: 'Status is required',
                      })}
                    ></input>
                    {errors.status && (
                      <span className="text-danger">
                        {errors?.status?.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>

                {/* <Col md={4}>
                  <FormGroup className="submit-form">
                    <label htmlFor="descInput">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      id="descInput"
                      {...register('description', {
                        required: 'Description is required',
                      })}
                    ></input>
                    {errors.description && (
                      <span className="text-danger">
                        {errors?.description?.message}
                      </span>
                    )}
                  </FormGroup>
                </Col> */}
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <FormGroup className="submit-form__btn-section">
              <Button
                type="submit"
                color="default"
                disabled={initState.submitLoading}
              >
                Save Record
              </Button>
            </FormGroup>
          </CardFooter>
        </Card>
      </Form>
    </>
  )
}
export default UpdatOrderRecord

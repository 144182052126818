import { useState, useRef } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import { dataUrlToFile } from 'utli/fileConverter'
import CropperModal from 'components/cropperModal'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'

const CreateNotification = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [cropImage, setCropImage] = useState(null)
  const inputFile = useRef(null)

  const [initState, setInitState] = useState({
    openCropper: false,
    image: null,
    croppedImage: null,
    imageError: false,
  })

  const submitData = async (values) => {
    if (!cropImage) {
      setInitState({ ...initState, imageError: true, submitLoading: true })
    } else {
      const formData = new FormData()
      const imageFile = await dataUrlToFile(cropImage)

      formData.append('title', values.title);
      formData.append('body', values.body);
      formData.append('image', imageFile);

      const response = await sendData('notifications/store', formData)
      if (response) {
        NotificationManager.success('Saved')
        window.location="/notification"
        reset()
        setInitState({ ...initState, croppedImage: null })
        setCropImage(null)
      }
    }
  }

  return (
    <>
      <Card>
        <Form onSubmit={handleSubmit(submitData)}>
          <CardBody>
            <Row>
              <Col>
                <CardTitle className="d-flex justify-content-between">
                  <h2 className="mt-2">Create Annocument</h2>
                </CardTitle>
                <Row>
                  <Col md="6">
                    <FormGroup className="submit-form">
                      <label htmlFor="userNameInput">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="userNameInput"
                        {...register('title', {
                          required: 'Title is required',
                        })}
                      ></input>
                      {errors.title && (
                        <span className="text-danger">
                          {errors?.title?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="submit-form">
                      <label>Body</label>
                      <textarea
                        className="form-control"
                        placeholder="Write  text here ..."
                        rows="5"
                        {...register('body', {
                          required: 'Body is required',
                        })}
                      />
                      {errors.body && (
                        <span className="text-danger">
                          {errors?.body?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <label>Image</label>
                  {cropImage ? (
                    <div>
                      <img
                        alt="croped result"
                        src={cropImage}
                        width="400"
                        height="400"
                        onClick={() => {
                          inputFile.current.click()
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        inputFile.current.click()
                      }}
                      style={{
                        width: 400,
                        height: 400,
                        background: 'grey',
                        cursor: 'pointer',
                      }}
                    ></div>
                  )}
                  {initState.imageError && (
                    <p className="text-danger">Image is required</p>
                  )}
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        setInitState({
                          ...initState,
                          openCropper: true,
                          image: URL.createObjectURL(event.target.files[0]),
                        })
                      }
                    }}
                  />
                </FormGroup>
                

                <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Save
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Form>
        {initState.openCropper && (
          <CropperModal
            isShow={initState.openCropper}
            image={initState.image}
            close={() => setInitState({ ...initState, openCropper: false })}
            setCroppedImage={async (image) => {
              setCropImage(image)
            }}
          />
        )}
      </Card>
    </>
  )
}
export default CreateNotification

import Home from 'pages/Home'
import Login from 'pages/Login'
import User from 'pages/user'
import Record from 'pages/record'
import Notificaton from 'pages/notification'
import CreateUserAccount from 'pages/user/create'
import EditUserAccount from 'pages/user/edit'
import CreateNotification from 'pages/notification/create'
import EditNotification from 'pages/notification/edit'
import ContactInfo from 'pages/contactInfo'
import Setting from 'pages/setting'
import Admin from 'pages/admin'
import CreateAdminAccount from 'pages/admin/create'
import EditAdminAccount from 'pages/admin/edit'
import RoyalCustomer from 'pages/user/royal'
// import UserReport from 'pages/user/report'

import CompanyService from 'pages/companyService'
import CreateCompanyService from 'pages/companyService/create'
import EditCompanyService from 'pages/companyService/edit'

import TermOfUse from 'pages/termOfUse'
import CompanyInfo from 'pages/companyInfo'
import CreateRecord from 'pages/record/create'
import RecordDetail from 'pages/record/detail'
import Finance from 'pages/finance'
import EditRecord from 'pages/record/edit'
import UpdatOrderRecord from 'pages/record/updateRecord'

// import ItemArrival from 'pages/arrival'
// import CreateItemArrival from 'pages/arrival/create'

// import Stock from 'pages/stock'

const routes = [
  {
    path: '/login',
    name: 'login',
    icon: 'fas fa-home text-primary',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '',
    name: 'Home',
    icon: 'fas fa-home text-danger',
    component: Home,
    layout: '/',
  },
  {
    path: 'records',
    name: 'Record',
    icon: 'fas fa-sort-alpha-down text-info',
    component: Record,
    layout: '/',
  },
  {
    path: 'records/create',
    name: 'Create Record',
    icon: 'fas fa-home text-primary',
    component: CreateRecord,
    layout: '/',
    invisible: true,
  },
  {
    path: 'record/:id',
    name: 'Record Detail',
    icon: 'fas fa-home text-primary',
    component: RecordDetail,
    layout: '/',
    invisible: true,
  },
  {
    path: 'record/edit/:id',
    name: 'Record Edit',
    icon: 'fas fa-home text-primary',
    component: EditRecord,
    layout: '/',
    invisible: true,
  },
  {
    path: 'record/update/:id/:status',
    name: 'Record Update',
    icon: 'fas fa-home text-primary',
    component: UpdatOrderRecord,
    layout: '/',
    invisible: true,
  },
  
  {
    path: 'finance',
    name: 'Finance',
    icon: 'fas fa-money-bill-wave text-success',
    state: 'nosCollapse',
    component: Finance,
    layout: '/',
  },
  {
    collapse: true,
    name: 'Users Account',
    icon: 'fas fa-user-friends text-info',
    state: 'usersCollapse',
    views: [
      {
        path: 'user',
        name: 'List',
        icon: 'fas fa-list text-warning',
        miniName: 'A',
        component: User,
        layout: '/',
      },
      {
        path: 'user/create',
        name: 'Create',
        icon: 'fas fa-user-plus text-info',
        miniName: 'C',
        component: CreateUserAccount,
        layout: '/',
      },
      {
        path: 'user/edit/:id',
        name: 'EditUser',
        miniName: 'C',
        component: EditUserAccount,
        layout: '/',
        invisible: true,
      },
      {
        path: 'user/royal',
        name: 'RoyalCustomer',
        miniName: 'C',
        component: RoyalCustomer,
        layout: '/',
        invisible: true,
      },
      // {
      //   path: 'user/report',
      //   name: 'Report',
      //   icon: 'fas fa-users text-primary',
      //   miniName: 'C',
      //   // component: UserReport,
      //   layout: '/',
      // },
    ],
  },
  {
    collapse: true,
    name: 'Admin Account',
    icon: 'fas fa-user-cog text-warning',
    state: 'adminCollapse',
    views: [
      {
        path: 'admin',
        name: 'List',
        icon: 'fas fa-list text-warning',
        miniName: 'A',
        component: Admin,
        layout: '/',
      },
      {
        path: 'admin/store',
        name: 'Create',
        icon: 'fas fa-user-plus text-info',
        miniName: 'C',
        component: CreateAdminAccount,
        layout: '/',
      },
      {
        path: 'admin/update/:id',
        name: 'EditAdmin',
        miniName: 'C',
        component: EditAdminAccount,
        layout: '/',
        invisible: true,
      },
    ],
  },

  {
    collapse: true,
    name: 'Notification',
    icon: 'fas fa-bell text-success',
    state: 'nosCollapse',
    views: [
      {
        path: 'notification',
        name: 'List',
        icon: 'fas fa-list text-warning',
        component: Notificaton,
        layout: '/',
      },
      {
        path: 'notification/create',
        name: 'New',
        icon: 'fas fa-plus-circle text-info',
        component: CreateNotification,
        layout: '/',
      },
      {
        path: 'notification/edit/:id',
        name: 'Edit',
        icon: 'fas fa-home text-primary',
        component: EditNotification,
        layout: '/',
        invisible: true,
      },
    ],
  },
  {
    path: 'contactinfo',
    name: 'Contact Info',
    icon: 'far fa-address-book text-warning',
    component: ContactInfo,
    layout: '/',
  },
  {
    collapse: true,
    path: 'setting',
    name: 'Setting',
    icon: 'fas fa-cogs text-danger',
    component: Setting,
    layout: '/',
    views: [
      {
        path: 'companyService',
        name: 'Company Service',
        icon: 'fa fa-truck text-dark',
        component: CompanyService,
        layout: '/',
      },
      {
        path: 'companyService/create',
        name: 'Company Service',
        icon: 'fas fa-money-bill-wave text-danger',
        component: CreateCompanyService,
        layout: '/',
        invisible: true,
      },
      {
        path: 'companyService/edit/:id',
        name: 'Company Service',
        icon: 'fas fa-money-bill-wave text-primary',
        component: EditCompanyService,
        layout: '/',
        invisible: true,
      },

      {
        path: 'tnc',
        name: 'Terms of Use',
        icon: 'fa fa-tasks text-danger',
        component: TermOfUse,
        layout: '/',
      },
      {
        path: 'companyInfo',
        name: 'Company Info',
        icon: 'fa fa-info text-info',
        component: CompanyInfo,
        layout: '/',
      },
    ],
  },
]

export default routes

import React, { useState, useCallback } from 'react'
import { CardBody, Modal, Card, CardTitle, Button } from 'reactstrap'
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
function CropperModal({
  isShow,
  image,
  close,
  setCroppedImage,
  width = 400,
  height = 400,
  x = 5.5,
  y = 4.5,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isShow}
      toggle={() => console.log('hi')}
    >
      <Card>
        <CardBody>
          <div
            className="crop-container"
            style={{ width: width, height: height }}
          >
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={x / y}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              restrictPosition={false}
              minZoom={0.2}
            />
          </div>
          <div className="controls"></div>
        </CardBody>
        <CardTitle className="d-flex justify-content-end pr-4">
          <Button
            onClick={async () => {
              try {
                const croppedImage = await getCroppedImg(
                  image,
                  croppedAreaPixels
                )
                setCroppedImage(croppedImage)
                close()
              } catch (e) {
                console.error(e)
              }
            }}
          >
            Crop
          </Button>
          <Button onClick={close}>Close</Button>
        </CardTitle>
      </Card>
    </Modal>
  )
}

export default CropperModal

// nodejs library that concatenates classes
import classnames from 'classnames'
import { useHistory, useLocation } from 'react-router'
import { removeCache } from 'lib/cache'

// reactstrap components
import {
  Collapse,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Button,
} from 'reactstrap'

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const router = useLocation()
  const history = useHistory()
  // function that on mobile devices makes the search open

  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove('g-navbar-search-shown')
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-show')
      document.body.classList.add('g-navbar-search-hiding')
    }, 150)
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hiding')
      document.body.classList.add('g-navbar-search-hidden')
    }, 300)
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hidden')
    }, 500)
  }

  const logout = () => {
    removeCache('token')
    history.push('/')
  }

  const showSearch = () => {
    const searchPages = ['/user', '/records', '/category', '/product'];
    if (searchPages.includes(router.pathname)) {
      return true
    }
    return false
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark bg-default navbar-expand border-bottom">
        <Container fluid>
          <div
            className={classnames(
              'pr-3 sidenav-toggler',
              { active: sidenavOpen },
              { 'sidenav-toggler-dark': theme === 'dark' }
            )}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
          <Collapse
            navbar
            isOpen={true}
            className="d-flex justify-content-between"
          >
            <Form
              className={classnames(
                'navbar-search form-inline mr-sm-3',
                { 'navbar-search-light': theme === 'dark' },
                { 'navbar-search-dark': theme === 'light' }
              )}
            >
              {showSearch() && (
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative input-group-merge">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search"
                      type="text"
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault()
                          history.push(
                            `${router.pathname}?search=${event.target.value}`
                          )
                        }
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              )}
              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={closeSearch}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </Form>

            <Nav className=" ml-auto ml-md-0" navbar>
              <Button onClick={logout}>Logout</Button>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
}

export default AdminNavbar

import React from 'react'
// react library for routing
import { useLocation, Switch } from 'react-router-dom'
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import Sidebar from 'components/Sidebar/Sidebar.js'
import ProtectedRoute from 'protectedRoutes/protectedRoute'
import { globalStyle } from 'styles/globalStyle'

import routes from 'routes.js'

function Admin(props) {
  const [sidenavOpen, setSidenavOpen] = React.useState(true)
  const location = useLocation()
  const mainContentRef = React.useRef(null)
  React.useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainContentRef.current.scrollTop = 0
  }, [location])
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }

      if (prop.layout === '/') {
        return (
          <ProtectedRoute
            path={prop.layout + prop.path}
            exact
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].path) !== -1) {
        return routes[i].name
      }
    }
    return 'Brand'
  }
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned')
      document.body.classList.add('g-sidenav-hidden')
    } else {
      document.body.classList.add('g-sidenav-pinned')
      document.body.classList.remove('g-sidenav-hidden')
    }
    setSidenavOpen(!sidenavOpen)
  }

  return (
    <>
      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
      />
      {globalStyle}
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        <div className="px-4 py-4">
          <Switch>
            {getRoutes(routes)}
            {/* <Redirect from="*" to="/admin/dashboard" /> */}
          </Switch>
        </div>
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  )
}

export default Admin

import { useState, useRef } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
} from 'reactstrap'

import { useForm } from 'react-hook-form'
import CropperModal from 'components/cropperModal'
import { dataUrlToFile } from 'utli/fileConverter'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'

const CreateCompanyService = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [initState, setInitState] = useState({
    openImageCropper: false,
    openIconCropper: false,
    icon: null,
    image: null,
    croppedIcon: null,
    croppedImage: null,
    iconError: false,
    imageError: false,
  })
  const [cropImage, setCropImage] = useState(null)
  const [cropIcon, setCropIcon] = useState(null)
  const inputIconFile = useRef(null)
  const inputImageFile = useRef(null)

  const submitData = async (values) => {
    // console.log(values);
    if (!cropIcon) {
      setInitState({ ...initState, iconError: true })
    } else if (!cropImage) {
      setInitState({ ...initState, imageError: true })
    } else {
      const formData = new FormData()
      const iconFile = await dataUrlToFile(cropIcon)
      const imageFile = await dataUrlToFile(cropImage)
      formData.append('name', values.name);
      formData.append('description', values.description);
      formData.append('logo', iconFile);
      formData.append('image', imageFile);
      const response = await sendData('services/store', formData)
      if (response) {
        NotificationManager.success('Save')
        window.location ='/companyService'
        reset()
        setInitState({ ...initState, croppedIcon: null, croppedImage: null });
        setCropIcon(null);
        setCropImage(null);
      }
   }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card>
          <CardTitle className="pt-2 pl-4 bg-dark">
            <h3 className="text-white">New Service</h3>
          </CardTitle>
          <CardBody>
            <Row>
              <Col sm="12">
                <label>Name</label>
                <input
                  className="form-control"
                  {...register('name', {
                    required: 'Name is required',
                  })}
                />
                {errors.name && (
                  <span className="text-danger">{errors?.name?.message}</span>
                )}
              </Col>
              <Col sm="12" className="mt-2">
                <label>Description</label>
                <textarea
                  className="form-control"
                  {...register('description', {
                    required: 'Description is required',
                  })}
                />
                {errors.description && (
                  <span className="text-danger">{errors?.description?.message}</span>
                )}
              </Col>

              <Col sm="12" className="pt-3">
                <label>Logo</label>
                {cropIcon ? (
                  <div>
                    <img
                      alt="croped result"
                      src={cropIcon}
                      width="200"
                      height="200"
                      onClick={() => {
                        inputIconFile.current.click()
                      }}
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      inputIconFile.current.click()
                    }}
                    style={{
                      width: 200,
                      height: 200,
                      background: 'grey',
                      cursor: 'pointer',
                    }}
                  ></div>
                )}
                {initState.iconError && (
                  <p className="text-danger">Icon is required</p>
                )}
                <input
                  type="file"
                  id="file"
                  ref={inputIconFile}
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    if (event.target.files[0]) {
                      setInitState({
                        ...initState,
                        openIconCropper: true,
                        icon: URL.createObjectURL(event.target.files[0]),
                      })
                    }
                  }}
                />
              </Col>
              <Col sm="12" className="pt-3">
                <label>Image</label>
                {cropImage ? (
                  <div>
                    <img
                      alt="croped result"
                      src={cropImage}
                      width="400"
                      height="400"
                      onClick={() => {
                        inputImageFile.current.click()
                      }}
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      inputImageFile.current.click()
                      // console.log("hello");
                    }}
                    style={{
                      width: 400,
                      height: 400,
                      background: 'grey',
                      cursor: 'pointer',
                    }}
                  ></div>
                )}
                {initState.imageError && (
                  <p className="text-danger">Image is required</p>
                )}
                <input
                  type="file"
                  id="file"
                  ref={inputImageFile}
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    if (event.target.files[0]) {
                      setInitState({
                        ...initState,
                        openImageCropper: true,
                        image: URL.createObjectURL(event.target.files[0]),
                      })
                    }
                  }}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="d-flex justify-content-center">
            <Button color="default" type="submit">
              Create
            </Button>
          </CardFooter>
        </Card>
      </Form>
      {initState.openIconCropper && (
        <CropperModal
          isShow={initState.openIconCropper}
          image={initState.icon}
          close={() => setInitState({ ...initState, openIconCropper: false })}
          setCroppedImage={async (icon) => {
            setCropIcon(icon)
          }}
          x={2}
          y={2}
        />
      )}

      {initState.openImageCropper && (
        <CropperModal
          isShow={initState.openImageCropper}
          image={initState.image}
          close={() => setInitState({ ...initState, openImageCropper: false })}
          setCroppedImage={async (image) => {
            setCropImage(image)
          }}
        />
      )}
    </Container>
  )
}
export default CreateCompanyService

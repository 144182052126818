import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Table,
  CardFooter,
} from 'reactstrap'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import CustomPagnation from 'components/pagination'
import useQuery from 'hooks/useQuery'
import { sendData } from 'utli/apiService'
import { paginationLimit } from 'variables/limits'

const CompanyInfo = () => {
  const router = useHistory()
  const headers = ['#', 'Image', 'Name', 'Actions']
  const deleteData = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete.Data cannot be recovered',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const response = await sendData(`services/delete/${id}`)
            if (response) {
              window.location.reload()
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  const query = queryString.parse(router.location.search)
  let url = 'services?'
  let page = query.page || 1
  if (query.search) url += `keyword=${query.search}`
  if (query.page) url += `&page=${page}`
  const {
    response: { servicelists },
    loading,
    error,
  } = useQuery(url)

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Company Services</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                  href={`${router.location.pathname}`}
                >
                  <span className="btn-inner--text">View All</span>
                </Button>

                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  onClick={(e) => router.push('/companyService/create')}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">Add New</span>
                </Button>
              </Col>
            </Row>
          </CardTitle>

          <div className="d-flex justify-content-end">
            <span>Total {servicelists?.total} services</span>
          </div>
          <Table responsive bordered hover className="text-center">
            <thead className='thead-light'>
              <tr>
                {headers.map((element, index) => {
                  return (
                    <th
                      className=" sort"
                      data-sort={element}
                      scope="col"
                      key={`user-account-new-header-${index}`}
                    >
                      {element}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {servicelists?.data.map((element, index) => {
                return (
                  <tr key={`user-account-list-${element.id}`}>
                    <td>
                      {index + 1 + page * paginationLimit - paginationLimit}
                    </td>
                    <td>
                      <img
                        alt="category list"
                        src={element.image}
                        width="80"
                        height="80"
                        style={
                        {
                          objectFit:"cover"
                        }
                        }
                      />
                    </td>
                    <td>{element.name}</td>
                    <td>
                      <div>
                        <Button
                          size="sm"
                          className="btn-icon-only"
                          color="success"
                          onClick={() => {
                            router.push(`/companyService/edit/${element.id}`)
                          }}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-pen" />
                          </span>
                        </Button>
                        <Button
                          size="sm"
                          className="btn-icon-only"
                          color="danger"
                          onClick={() => deleteData(element.id)}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-trash" />
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </CardBody>
        {servicelists?.total > paginationLimit && (
          <CardFooter className="d-flex justify-content-end">
            <CustomPagnation
              pageCount={servicelists?.total / paginationLimit}
            ></CustomPagnation>
          </CardFooter>
        )}
      </Card>
    </>
  )
}
export default CompanyInfo

import { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
} from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'
import useQuery from 'hooks/useQuery'

const EditRecord = (props) => {
  const types = [
    {
      label: 'Export',
      value: 'export',
    },
    {
      label: 'Import',
      value: 'import',
    },
  ]
  // const customers = [
  //   {
  //     label: 'Mg Mg',
  //     value: 'mg mg',
  //   },
  //   {
  //     label: 'Kyaw Kyaw',
  //     value: 'kyaw kyaw',
  //   },
  // ]

  const customerTypes = [
    {
      label: 'Member',
      value: '0',
    },
    {
      label: 'Guest',
      value: '1',
    },
  ]

  const {
    response: { orderInfo },
    loading: orderLoading,
    error: orderLoadingErr,
  } = useQuery(`orders/detail/${props.match.params.id}`)

  

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  const [initState, setInitState] = useState({
    submitLoading: false,
  })

  const submitData = async (values) => {
    // console.log(values);
    // values.id =props.match.params.id
    values.user_id = orderInfo.user.id
    values.id = orderInfo.id
    // console.log(values);
    setInitState({ ...initState, submitLoading: true })

    const response = await sendData('orders/updateOrder', values)
    if (response) {
      NotificationManager.success('Save')
      window.location=`/record/${orderInfo.id}`;
      setInitState({
        ...initState,
        submitLoading: false,
      })
      reset()
    }
  }

  if (orderLoading) return <h3>Loading...</h3>
  if (orderLoadingErr) return <h3>orderLoadingErr.message</h3>

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Edit Record</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <Row>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="tradeTypeInput">Type</label>
                      <Controller
                        control={control}
                        name="type"
                        className="tradeTypeInput"
                        defaultValue={orderInfo.type}
                        rules={{ required: true }}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            inputRef={ref}
                            classNamePrefix="addl-class"
                            options={types}
                            isDisabled={true}
                            defaultValue={orderInfo.type}
                            value={types.find((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                          />
                        )}
                      />
                    </FormGroup>
                    {errors.type && (
                      <span className="text-danger">
                        {errors?.type?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="customerInput">Customer Type</label>
                      <Controller
                        control={control}
                        name="is_guest"
                        className="customerInput"
                        defaultValue={orderInfo.is_guest}
                        rules={{ required: true }}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            inputRef={ref}
                            classNamePrefix="addl-class"
                            name="is_guest"
                            options={customerTypes}
                            isDisabled={true}
                            // isSearchable={false}
                            // isOptionDisabled={(option)=>option.isOptionDisabled}
                            value={customerTypes.find((c) => c.value === value)}
                            onChange={(val) => {
                              onChange(val.value)
                            }}
                          />
                        )}
                      />
                      {errors.is_guest && (
                        <span className="text-danger">
                          {errors?.is_guest?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="phoneInput">BL / AWB No</label>
                      <input
                        type="text"
                        defaultValue={orderInfo.number}
                        className="form-control"
                        id="billNo"
                        {...register('number', {
                          required: 'Bill Number is required',
                        })}
                      ></input>
                      {errors.number && (
                        <span className="text-danger">
                          {errors?.number?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  {orderInfo.is_guest === '1' ? (
                    <Row>
                      <Col md={6}>
                        <FormGroup className="submit-form">
                          <label htmlFor="guestNameInput">
                            Guest User Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={orderInfo.guest_name}
                            id="guestName"
                            {...register('guest_name', {
                              required: 'Phone is required',
                            })}
                          ></input>
                          {errors.guest_name && (
                            <span className="text-danger">
                              {errors?.guest_name?.message}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="submit-form">
                          <label htmlFor="guestPhoneInput">Guest Phone</label>
                          <input
                            type="tel"
                            className="form-control"
                            defaultValue={orderInfo.guest_phone}
                            id="billNo"
                            {...register('guest_phone', {
                              required: 'Phone is required',
                            })}
                          ></input>
                          {errors.guest_phone && (
                            <span className="text-danger">
                              {errors?.guest_phone?.message}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <div></div>
                    // <Col md={4}>
                    //   <FormGroup className="submit-form">
                    //     <label htmlFor="userIdInput">Customer</label>
                    //     <input
                    //       type="text"
                    //       defaultValue={orderInfo.user.id}
                    //       className="form-control"
                    //       id="userIdInput"
                    //       {...register('user_id', {
                    //         required: 'User name is required',
                    //       })}
                    //     ></input>

                    //     {errors.user_id && (
                    //       <span className="text-danger">
                    //         {errors?.user_id?.message}
                    //       </span>
                    //     )}
                    //   </FormGroup>
                    // </Col>
                  )}

                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="incomeInput">Income</label>
                      <input
                        type="text"
                        // defaultValue={orderInfo.number}
                        className="form-control"
                        defaultValue={orderInfo.income}
                        id="income"
                        {...register('income', {
                          required: 'Income amount is required',
                        })}
                      ></input>
                      {errors.income && (
                        <span className="text-danger">
                          {errors?.income?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="incomeInput">Expense</label>
                      <input
                        type="text"
                        defaultValue={orderInfo.expense}
                        className="form-control"
                        id="expense"
                        {...register('expense', {
                          required: 'Expense Amount is required',
                        })}
                      ></input>
                      {errors.expense && (
                        <span className="text-danger">
                          {errors?.expense?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup className="submit-form__btn-section mt-5">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Update Record
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
export default EditRecord

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getCache } from 'lib/cache'
const ProtectedRoute = ({ component: Component, ...restProps }) => {
  const token = getCache('token')

  return (
    <Route
      {...restProps}
      render={(props) => {
        if (token) {
          return <Component {...props} />
        } else {
          return (
            <Redirect
              to={{
                pathname: '/auth/login',
              }}
            />
          )
        }
      }}
    />
  )
}
export default ProtectedRoute
